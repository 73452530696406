import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import config from '../config.json'
import LegendaryFilter from './pieces/LegendaryFilter'
// import link from '../img/link.svg'
// import pvpImg from '../img/pvp.png'
// import coopImg from '../img/coop.png'
// import crewImg from '../img/crew.png'

export class Decklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decks: [],
            votes: [],
            sort: 'votes',
            type: '',
            loading: true,
            voting: null,
            filter: [],
            showFilter: false,
        };

        this.setSort = this.setSort.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
    }

    setFilter(filter) {
        this.setState(() => ({filter}));
    }

    toggleFilter() {
        this.setState(() => ({showFilter: !this.state.showFilter}));
    }

    showDeck(dice) {
        for (let die of dice) {
            if (this.state.filter.includes(die)) {
                return false;
            }
        }

        return true;
    }

    setSort(e) {
        const sort = e.target.getAttribute('data-sort');
        const {decks} = this.state;
        decks.sort((a, b) => {
            if (sort === 'newest') {
                return a.id < b.id;
            }

            return a.votes < b.votes;
        })

        this.setState(() => ({decks, sort}));
    }

    getDecks(sort = null) {
        const {type} = this.props.match.params;
        this.setState(() => ({type: type, loading: true}));

        const fd = new FormData();
        fd.append('token', this.props.user?.token || null);
        fd.append('sort', sort || this.state.sort);

        fetch(`${config.api_url}/decks/${type}`, {
            method: 'POST',
            body: fd,
        })
            .then(res => res.json())
            .then(data => {
                this.setState(() => ({
                    decks: data.decks,
                    votes: data.votes,
                    loading: false
                }));
            });
    }

    vote(deckID, e) {
        this.setState(() => ({voting: deckID}));

        const fd = new FormData();
        fd.append('token', this.props.user?.token || null);

        fetch(`${config.api_url}/vote/${deckID}`, {
            method: 'POST',
            body: fd,
        })
        .then(res => res.json())
        .then(data => {
            const state = {voting: null};
            if (data['success']) {
                state['votes'] = this.state.votes.slice();
                state['votes'].push(deckID);
                state['decks'] = this.state.decks.map(deck => {
                    if (deck.id === deckID) deck.votes++;
                    return deck;
                });
            }

            this.setState(() => (state));
        })
    }

    unvote(deckID, e) {
        this.setState(() => ({voting: deckID}));

        const fd = new FormData();
        fd.append('token', this.props.user?.token || null);

        fetch(`${config.api_url}/unvote/${deckID}`, {
            method: 'POST',
            body: fd,
        })
        .then(res => res.json())
        .then(data => {
            const state = {voting: null};
            if (data['success']) {
                state['votes'] = this.state.votes.slice();
                const idx = state['votes'].indexOf(deckID);
                if (idx !== -1) {
                    state['votes'].splice(idx, 1);
                }

                state['decks'] = this.state.decks.map(deck => {
                    if (deck.id === deckID) deck.votes--;
                    return deck;
                });
            }

            this.setState(() => (state));
        })
    }

    componentDidMount() {
        this.getDecks.call(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.type !== this.props.match.params.type) {
            this.setState(() => ({decks: [], votes: []}))
            this.getDecks();
        }
    }

    render() {
        const {type, votes, loading, voting, sort, filter, showFilter} = this.state;
        const {user} = this.props;
        const labels = {
            'pvp': 'PVP',
            'crew': 'Crew',
            'coop': 'Co-op',
        };
        let label = labels[type];
        const single = /^\d+$/.test(type);

        const decks = this.state.decks.filter(deck => this.showDeck([deck.d1id, deck.d2id, deck.d3id, deck.d4id, deck.d5id]));

        return (
            <div className='container'>
                <div className='deck-list flex-scroll'>
                    <div className='header'>
                        {single ? (
                            <h2 className='center'>Single Deck</h2>
                        ) : (<>
                            <h2 className='center'>{label} Decks {}</h2>
                            <h4 className='center'>
                                <div className={`deck-sort ${sort === "votes" ? "active" : ''}`} data-sort='votes' onClick={this.setSort}>Votes</div>
                                <div className={`deck-sort ${sort === "newest" ? "active" : ''}`} data-sort='newest' onClick={this.setSort}>Newest</div>
                            </h4>
                        </>)}
                    </div>
                    {!single ? (<>
                        {showFilter ? (
                            <LegendaryFilter filter={filter} setFilter={this.setFilter} />
                        ) : ''}
                        <div className='center filter-toggle'>
                            <span className='pointer' onClick={this.toggleFilter}>{showFilter ? 'Close' : 'Legendary'} Filter</span>
                        </div>
                    </>) : ''}
                    {loading ? <div className='loader'></div> : ''}
                    {!loading && decks.length === 0 ? (
                        <div className='alert center'>No {label} decks yet</div>
                    ) : (
                        <div className='decks scroll'>
                            {decks.map(deck => (
                                <div key={deck.id} className='flex-deck secondary'>
                                    <div className='dice'>
                                        <img src={`https://www.rddb.xyz/img/${deck.d1image}`} alt={deck.d1name} />
                                        <div className='name'>{deck.d1name}</div>
                                        <img src={`https://www.rddb.xyz/img/${deck.d2image}`} alt={deck.d2name} />
                                        <div className='name'>{deck.d2name}</div>
                                        <img src={`https://www.rddb.xyz/img/${deck.d3image}`} alt={deck.d3name} />
                                        <div className='name'>{deck.d3name}</div>
                                        <img src={`https://www.rddb.xyz/img/${deck.d4image}`} alt={deck.d4name} />
                                        <div className='name'>{deck.d4name}</div>
                                        <img src={`https://www.rddb.xyz/img/${deck.d5image}`} alt={deck.d5name} />
                                        <div className='name'>{deck.d5name}</div>
                                    </div>
                                    <hr />
                                    <div className='voting'>
                                        <div>
                                            {user && votes.includes(deck.id) ? (
                                                <button className='btn' onClick={this.unvote.bind(this, deck.id)}>
                                                    {voting === deck.id ? <div className='loader'></div> : 'Remove Vote'}
                                                </button>
                                            ) : ''}
                                        </div>

                                        <div>
                                            <div>{deck.votes} Votes</div>
                                        </div>

                                        <div>
                                            {user && !votes.includes(deck.id) ? (
                                                <button className='btn' onClick={this.vote.bind(this, deck.id)}>
                                                    {voting === deck.id ? <div className='loader'></div> : 'Vote'}
                                                </button>
                                            ) : ''}
                                        </div>
                                    </div>
                                    {/* <div className="deck-link">
                                        <img src={link} alt="" />
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(Decklist)

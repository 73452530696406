import React, { Component } from 'react'

export class PasswordInput extends Component {
    render() {
        const {id, label, value, onChange, required} = this.props;
        return (
            <div className='form-group'>
                <label htmlFor={id}>{label}</label>
                {required ? (
                    <input type='password' className='form-control' name={id} id={id} value={value} onChange={onChange} required />
                ) : (
                    <input type='password' className='form-control' name={id} id={id} value={value} onChange={onChange} />
                )}
            </div>
        )
    }
}

export default PasswordInput

import React, { Component } from 'react'
import {withRouter, Link} from 'react-router-dom'
import config from '../config.json'
import TextInput from './form/TextInput'
import FormButtons from './form/FormButtons'
import PasswordInput from './form/PasswordInput'

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: '',
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.submit = this.submit.bind(this)
        this.reset = this.reset.bind(this)
    }

    setError(error) {
        this.setState({...this.state, error: error})
    }

    submit(e) {
        e.preventDefault();
        this.setState(() => ({loading: true}))

        const submit = async () => {
            const formdata = new FormData();
            formdata.append('username', this.state.username);
            formdata.append('password', this.state.password);

            const res = await fetch(config.api_url + '/login', {
                'method': 'POST',
                'body': formdata
            });
            const data = await res.json();

            return data;
        }

        const another = async () => {
            const data = await submit();

            if (typeof data['username'] !== 'undefined') {
                this.props.setUser(data);
                const expiry = new Date();
                expiry.setTime(data.expires * 1000)

                document.cookie = 'username=' + data.username + ';expires=' + expiry.toUTCString() + ';path=/'
                document.cookie = 'userID=' + data.userID + ';expires=' + expiry.toUTCString() + ';path=/'
                document.cookie = 'token=' + data.token + ';expires=' + expiry.toUTCString() + ';path=/'

                const redir = this.props.location.state?.redir;
                this.props.history.push(redir ? redir : '/');
            } else {
                this.setError('Failed to login');
                this.setState(() => ({loading: false}))
            }
        }

        another()
    }

    handleChange(e) {
        const {name, value, validity} = e.target;
        if (validity.valid || value === '') {
            this.setState({[name]: value});
        }
    }

    reset(e) {
        this.setState({
            ...this.state,
            username: '',
            password: '',
        })
    }

    render() {
        const {username, password, error, loading} = this.state;
        const {user} = this.props;

        return (
            <div className='container'>
                <h2 className='center'>Login</h2>
                {user ? (
                    <h3 className='center'>You are already logged in</h3>
                ) : (<>
                    <form className='form' onSubmit={this.submit} onReset={this.reset}>
                        {error !== '' ? <div className='alert danger'>{error}</div> : ''}
                        <TextInput
                            id='username'
                            label='Username'
                            value={username}
                            onChange={this.handleChange}
                            required={true} />
                        <PasswordInput
                            id='password'
                            label='Password'
                            value={password}
                            onChange={this.handleChange}
                            required={true} />
                        {loading ? (
                            <FormButtons label={<div className='loader'></div>} className='disabled' />
                        ) : (
                            <FormButtons label='Login' />
                        )}
                    </form>

                    <div className='center'>
                        <Link to='/register'>Register for an account</Link>
                    </div>
                </>)}
            </div>
        )
    }
}

export default withRouter(Login)

import React, { Component } from 'react'
import DiceRow from './pieces/DiceRow';

export class DiceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dice: [],
            loading: true,
            clevel: 1,
        }

        this.setDice = this.setDice.bind(this);
        this.setClevel = this.setClevel.bind(this);
    }

    componentDidMount() {
        fetch('https://rddb.xyz/api/dice')
        .then(res => res.json())
        .then(data => {
            this.setDice(data);
            this.setLoading(false);
        });
    }

    setClevel(e) {
        const increment = parseInt(e.target.getAttribute('data-inc'));
        let clevel = this.state.clevel + increment;
        if (clevel < 1) clevel = 1;
        if (clevel > 15) clevel = 15;
        this.setState(() => ({clevel}));
    }

    setDice(dice) {
        this.setState(() => ({dice}));
    }

    setLoading(loading) {
        this.setState(() => ({loading}));
    }

    render() {
        const {dice, loading, clevel} = this.state;

        return (
            <div className='container'>
                <div className='flex-scroll'>
                    <div className='header'>
                        <h2 className='center'>Dice</h2>
                        <div className='class-changer'>
                            <p>Class:</p>
                            <div className='minus' onClick={this.setClevel} data-inc={-1}>-</div>
                            <div className='clevel'>{clevel}</div>
                            <div className='plus' onClick={this.setClevel} data-inc={1}>+</div>
                        </div>
                    </div>
                    <div className='scroll'>
                        {loading ? <div className='loader'></div> : (
                            <table className='table striped'>
                                <thead>
                                    <tr>
                                        <th>Rarity</th>
                                        <th>Name</th>
                                        <th>Image</th>
                                        <th>Type</th>
                                        <th>Target</th>
                                        <th>Attack</th>
                                        <th>Speed</th>
                                        <th>DPS</th>
                                        <th>Effect</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dice.map(die => (
                                        <DiceRow key={die.id} die={die} clevel={clevel} />
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default DiceList

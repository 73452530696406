import React, { Component } from 'react'
import {Route, Redirect, withRouter} from 'react-router-dom'

export class AuthRoute extends Component {
    render() {
        const {user, children, location, ...rest} = this.props;
        const path = location.pathname;
        return (
            <Route
                {...rest}
                render={() =>
                    user !== null ? (
                        children
                    ) : (
                        <Redirect to={{pathname: '/login', state: {redir: path}}} />
                )} />
        )
    }
}

export default withRouter(AuthRoute)

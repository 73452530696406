import React, { Component } from 'react'
import {Redirect} from 'react-router-dom'

export class Logout extends Component {
    componentDidMount() {
        this.props.setUser(null);

        document.cookie = 'username=;expires=' + (new Date()).toUTCString() + ';path=/'
        document.cookie = 'userID=;expires=' + (new Date()).toUTCString() + ';path=/'
        document.cookie = 'token=;expires=' + (new Date()).toUTCString() + ';path=/'
    }

    render() {
        return (
            <Redirect to='/' />
        )
    }
}

export default Logout

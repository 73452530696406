import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import getCookie from './utils/getCookie'
import App from './App';
import reportWebVitals from './reportWebVitals';

if (getCookie('darkmode') === 'true' || window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('data-theme', 'dark');
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();

import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import NavLinks from './pieces/NavLinks'

export class Nav extends Component {
    closeMobileNav() {
        document.querySelector('#hamburger-button').checked = false;
    }

    render() {
        const {user} = this.props;
        const links = [
            {label: 'Home', url: '/'},
            {label: 'Dice', url: '/dice'},
            [
                'Decks',
                {label: 'PVP Decks', url: '/deck/pvp'},
                {label: 'Crew Decks', url: '/deck/crew'},
                {label: 'Co-op Decks', url: '/deck/coop'},
            ],
        ];
        const loggedInLinks = [
            [
                user?.username,
                {label: 'Add Deck', url: '/addDeck'},
                {label: 'Logout', url: '/logout'},
            ]
        ];

        return (
            <>
            <nav>
                <div>
                    <ul>
                        <li className='brand'><Link to="/">RDDB</Link></li>
                        <NavLinks links={links} />
                    </ul>
                    <ul>
                        {user ? (
                            <NavLinks links={loggedInLinks} onClick={this.closeMobileNav} />
                        ) : (
                            <li><Link to='/login' onClick={this.closeMobileNav}>Login</Link></li>
                        )}
                    </ul>
                </div>
                <div className='hamburger'>
                    <input type='checkbox' id='hamburger-button' />
                    <label htmlFor='hamburger-button'>
                        &#9776;
                        <span className='brand'>RDDB</span>
                    </label>
                    <div className='menu'>
                        <ul>
                            <NavLinks links={links} onClick={this.closeMobileNav} />
                            {user ? (
                                <NavLinks links={loggedInLinks} onClick={this.closeMobileNav} />
                            ) : (
                                <li><Link onClick={this.closeMobileNav} to='/login'>Login</Link></li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
            </>
        )
    }
}

export default Nav

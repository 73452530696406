import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class TextInput extends Component {
    render() {
        const {id, label, value, onChange, pattern, required} = this.props;
        return (
            <div className='form-group'>
                <label htmlFor={id}>{label}</label>
                {required ? (
                    <input type='text' className='form-control' name={id} id={id} value={value} onChange={onChange} pattern={pattern} required />
                ) : (
                    <input type='text' className='form-control' name={id} id={id} value={value} onChange={onChange} pattern={pattern} />
                )}
            </div>
        )
    }
}

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    pattern: PropTypes.string,
}

export default TextInput

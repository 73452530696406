import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import config from '../config.json'
import pvpImg from '../img/pvp.png'
import coopImg from '../img/coop.png'
import crewImg from '../img/crew.png'

export class AddDeck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pool: [],
            type: '',
            loading: false,
            slots: {
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
            },
        }

        this.onPoolClick = this.onPoolClick.bind(this);
        this.onSlotClick = this.onSlotClick.bind(this);
        this.changeType = this.changeType.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        fetch(`${config.api_url}/dice`)
        .then(res => res.json())
        .then(data => {
            this.setState(() => ({pool: data}));
        });
    }

    sortDice(a, b) {
        const rarity = {Legendary: 1, Unique: 2, Rare: 3, Common: 4};
        return (rarity[a.rarity] === rarity[b.rarity]) ? a.id > b.id : rarity[a.rarity] < rarity[b.rarity];
    }

    setSlots(slots) {
        this.setState(() => ({slots}))
    }

    shiftOn(fifth) {
        const curSlots = this.state.slots;
        const slots = {
            1: curSlots[2],
            2: curSlots[3],
            3: curSlots[4],
            4: curSlots[5],
            5: fifth
        };

        this.setSlots(slots);
    }

    shiftOff(slot) {
        slot = parseInt(slot);
        const curSlots = this.state.slots;
        const slots = {...curSlots};
        for (let i = slot; i > 0; i--) {
            slots[i] = curSlots[i-1];
        }

        this.setSlots(slots);
    }

    onPoolClick(e) {
        const index = e.target.getAttribute('data-index');
        if (!index) return;
        const {slots, pool} = this.state
        const dice = pool[index];
        const slotDice = [
            slots[1]?.id,
            slots[2]?.id,
            slots[3]?.id,
            slots[4]?.id,
            slots[5]?.id,
        ];

        if (!slotDice.includes(dice.id)) {
            this.shiftOn(dice);
        }
    }

    onSlotClick(e) {
        const slot = e.target.getAttribute('data-slot');
        if (!slot) return;
        const dice = this.state.slots[slot];
        if (!dice) return;

        this.shiftOff(slot);
    }

    changeType(e) {
        this.setState(() => ({type: e.target.value}));
    }

    submit(e) {
        if (this.state.loading) return;

        this.setState(() => ({loading: true}));
        const {slots, type} = this.state;
        const fd = new FormData();
        fd.append('token', this.props.user?.token);
        fd.append('type', type);
        fd.append('dice1', slots[1].id);
        fd.append('dice2', slots[2].id);
        fd.append('dice3', slots[3].id);
        fd.append('dice4', slots[4].id);
        fd.append('dice5', slots[5].id);

        fetch(`${config.api_url}/insert`, {
            method: 'POST',
            body: fd
        })
        .then(res => res.json())
        .then(data => {
            if (data.deckID) {
                this.props.history.push(`/deck/${data.deckID}`);
            } else if (data.success) {
                this.props.history.push(`/deck/${type}`);
            }
        })
        .finally(() => {
            this.setState(() => ({loading: false}));
        });
    }

    render() {
        const {slots, pool, loading} = this.state;
        const {img_base} = config;
        return (
            <div className='container'>
                <div className='flex-scroll'>
                    <h2 className='center'>Create a Deck</h2>
                    <div className='deck-types' onChange={this.changeType}>
                        <input type='radio' name='type' value='pvp' id='pvp' />
                        <label htmlFor='pvp'><img src={pvpImg} alt="PVP" />PVP</label>
                        <input type='radio' name='type' value='coop' id='coop' />
                        <label htmlFor='coop'><img src={coopImg} alt="Co-Op" />Co-Op</label>
                        <input type='radio' name='type' value='crew' id='crew' />
                        <label htmlFor='crew'><img src={crewImg} alt="Crew" />Crew</label>
                    </div>
                    <div className='slots'>
                        {Object.keys(slots).map(index => (
                            <div key={index} className='slot'>
                                {slots[index] ? (<>
                                    <img
                                        src={`${img_base}/${slots[index].image}`}
                                        alt={slots[index].name}
                                        data-slot={index}
                                        onClick={this.onSlotClick} />
                                    {slots[index].name}
                                </>) : (<>
                                    <img className='empty' src={`${img_base}/blank.png`} alt="Choose dice" />
                                    &nbsp;
                                </>) }
                            </div>
                        ))}
                    </div>
                    <button className='btn success' style={{width: 'min-content', margin: 'auto'}} onClick={this.submit}>
                        {loading ? <div className='loader'></div> : 'Post Deck'}
                    </button>
                    <hr />

                    <div className='scroll dice-pool'>
                        {pool.map((dice, index) => (
                            <img
                                key={index}
                                data-index={index}
                                src={`${img_base}/${dice.image}`}
                                alt={dice.label}
                                onClick={this.onPoolClick} />
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AddDeck)

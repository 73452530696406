import React, { Component } from 'react'

export class DiceRow extends Component {
    render() {
        const {die, clevel} = this.props;

        const calc = (rarity, base, cup) => {
            base = parseFloat(base);
            cup = parseFloat(cup);
            const minClev = {
                Legendary: 7,
                Unique: 5,
                Rare: 3,
                Common: 1,
            }
            const min = minClev[rarity];
            if (min > clevel) return base;

            return Math.round((base + (cup * (clevel - min))) * 100) / 100;
        }

        const atk = calc(die.rarity, die.atk, die.cupAtk);
        const spd = calc(die.rarity, die.spd, die.cupSpd);
        const eff1 = calc(die.rarity, die.eff1, die.cupEff1)
        const eff2 = calc(die.rarity, die.eff2, die.cupEff2)
        const dps = Math.round(atk / spd, 2);

        return (
            <tr>
                <td>{die.rarity}</td>
                <td>{die.name}</td>
                <td><img src={`https://rddb.xyz/img/${die.image}`} alt={die.name} /></td>
                <td>{die.type}</td>
                <td>{die.target}</td>
                <td>{atk === 0 ? '-' : atk}</td>
                <td>{spd === 0 ? '-' : spd}</td>
                <td>{isNaN(dps) ? '-' : dps}</td>
                <td>
                    {die.nameEff1}
                    {die.nameEff1 !== '-' ? `: ${eff1}${die.unitEff1}` : ''}
                    {die.nameEff2 !== '-' ? <><br />{die.nameEff2}</> : ''}
                    {die.nameEff2 !== '-' ? `: ${eff2}${die.unitEff2}` : ''}
                </td>
            </tr>
        )
    }
}

export default DiceRow

import React, { Component } from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import './app.css'
import getCookie from './utils/getCookie'
import AuthRoute from './components/AuthRoute'
import Nav from './components/Nav'
import Index from './components/Index'
import Login from './components/Login'
import Logout from './components/Logout'
import Decklist from './components/Decklist'
import AddDeck from './components/AddDeck'
import DiceList from './components/DiceList'
import ToggleDarkmode from './components/ToggleDarkmode'
import Register from './components/Register'

export class App extends Component {
    constructor(props) {
        super(props);

        const user = this.checkUserCookies();
        this.state = {
            user: user,
        }

        this.setUser = this.setUser.bind(this);
    }

    checkUserCookies() {
        const username = getCookie('username');
        const userID = getCookie('userID');
        const token = getCookie('token');
        if (token && username && userID) {
            return {userID, username, token};
        }

        return null;
    }

    setUser(user) {
        this.setState(() => ({user}))
    }

    render() {
        const {user} = this.state;

        return (
            <Router>
                <div className='flex-scroll'>
                    <Nav user={user} />
                    <Route path='/' exact>
                        <Index />
                    </Route>
                    <Route path='/register'>
                        <Register user={user} setUser={this.setUser} />
                    </Route>
                    <Route path='/login'>
                        <Login user={user} setUser={this.setUser} />
                    </Route>
                    <Route path='/logout'>
                        <Logout user={user} setUser={this.setUser} />
                    </Route>
                    <Route path='/deck/:type'>
                        <Decklist user={user} />
                    </Route>
                    <AuthRoute user={user} path='/addDeck'>
                        <AddDeck user={user} />
                    </AuthRoute>
                    <Route path='/darkmode' component={ToggleDarkmode} />
                    <Route path='/dice'>
                        <DiceList />
                    </Route>
                </div>
            </Router>
        )
    }
}

export default App

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class FormButtons extends Component {
    render() {
        const {label, className} = this.props;
        return (
            <div className='form-group'>
                <button type='submit' className={`btn success ${className}`}>{label ? label : 'Submit'}</button>
                <button type='reset' className='btn danger'>Reset Form</button>
            </div>
        )
    }
}

FormButtons.propTypes = {
    label: PropTypes.string,
}

export default FormButtons

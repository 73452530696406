import React, { Component } from 'react'
import {Link} from 'react-router-dom'

export class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(e) {
        this.setState(() => ({isOpen: !this.state.isOpen}));
    }

    render() {
        const {label, children, links, path, onClick} = this.props
        const {isOpen} = this.state;

        return (
            <div className='dropdown' data-active={isOpen} onClick={this.toggle}>
                {label} &#x25be;
                <div className='menu'>
                    <ul>
                        {links ? links.map(link => (
                            <li key={link.label} className={link.url === path ? 'active' : ''}>
                                <Link to={link.url} onClick={onClick}>{link.label}</Link>
                            </li>
                        )) : ''}
                        {children?.map((child, index) => (<li key={index}>{child}</li>))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Dropdown

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

export class Index extends Component {
    render() {
        const path = this.props.location.pathname;

        return (
            <div className='container'>
                <h1>Random Dice Database</h1>
                <div className='alert'>
                    <p>Welcome to the Random Dice Database (RDDB).</p>
                    <p>The goal of this website is to be a platform for users to share decks and look up high rated decks.</p>
                    <p>If you register for an account, you can submit your own deck, or vote on others!</p>
                </div>
                <div className='alert'>
                    <Link to={{pathname: '/darkmode', state: {redir: path}}}>Toggle Darkmode</Link>
                </div>
                <div className='alert'>
                    <h4>This site is under construction :)</h4>
                </div>
                <div className='warning alert center unaffiliated'>
                    <p>This site is unaffiliated with 111%</p>
                    <p>Dice and Game Mode Images belong to 111% and are in no way owned or claimed to be owned or created by the creator of this site.</p>
                </div>
            </div>
        )
    }
}

export default withRouter(Index)

import React, { Component } from 'react'
import config from '../../config.json'

export class LegendaryFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dice: [],
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const filter = this.props.filter;
        const id = e.target.id.replace(/.*-/, '');
        if (filter.includes(id)) {
            this.props.setFilter(filter.filter(dieID => dieID !== id));
        } else {
            filter.push(id);
            this.props.setFilter(filter);
        }
    }

    componentDidMount() {
        fetch(`${config.api_url}/dice/Legendary`)
        .then(res => res.json())
        .then(dice => this.setState({dice}))
    }

    render() {
        const {dice} = this.state;
        const {filter, className} = this.props;

        return (
            <div className={`leg-filter ${className}`}>
                {dice.map(die => (
                    <div key={die.id} className='leg-dice'>
                        <input type='checkbox' id={`leg-${die.id}`} checked={!filter.includes(die.id)} onChange={this.onChange} />
                        <label htmlFor={`leg-${die.id}`}><img src={`${config.img_base}/${die.image}`} alt={die.name} /></label>
                    </div>
                ))}
            </div>
        )
    }
}

export default LegendaryFilter

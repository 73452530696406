import React, { Component } from 'react'
import TextInput from './form/TextInput'
import PasswordInput from './form/PasswordInput'
import FormButtons from './form/FormButtons'
import config from '../config.json'
import { withRouter } from 'react-router'

export class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            loading: false,
            form: {
                username: '',
                email: '',
                password: '',
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
        this.reset = this.reset.bind(this);
    }

    handleChange(e) {
        const {name, value, validity} = e.target;
        if (validity.valid || value === '') {
            const newForm = {...this.state.form};
            newForm[name] = value;
            this.setState({...this.state, form: newForm});
        }
    }

    submit(e) {
        e.preventDefault();
        this.setState(() => ({loading: true}));

        const {username, email, password} = this.state.form;
        const fd = new FormData();
        fd.append('username', username);
        fd.append('email', email);
        fd.append('password', password);

        fetch(`${config.api_url}/register`, {
            method: 'POST',
            body: fd,
        })
        .then(res => res.json())
        .then(data => {
            if (data.error) {
                this.setState(() => ({error: data.error, loading: false}));
            }
            if (data.username) {
                this.props.setUser(data);
                const expiry = new Date();
                expiry.setTime(data.expires * 1000)

                document.cookie = 'username=' + data.username + ';expires=' + expiry.toUTCString() + ';path=/'
                document.cookie = 'userID=' + data.userID + ';expires=' + expiry.toUTCString() + ';path=/'
                document.cookie = 'token=' + data.token + ';expires=' + expiry.toUTCString() + ';path=/'

                this.props.history.push('/');
            }
        })
    }

    reset(e) {
        this.setState({
            ...this.state,
            form: {
                username: '',
                password: '',
                email: '',
            }
        })
    }

    render() {
        const {user} = this.props;
        const {form, error, loading} = this.state;
        const {username, password, email} = form;

        return (
            <div className='container'>
                <h2 className='center'>Register</h2>
                {user ? (
                    <h3 className='center'>You are already logged in</h3>
                ) : (
                    <form className='form' onSubmit={this.submit} onReset={this.reset}>
                        {error !== '' ? <div className='alert danger'>{error}</div> : ''}
                        <TextInput
                            id='username'
                            label='Username'
                            value={username}
                            onChange={this.handleChange}
                            required={true} />
                        <TextInput
                            id='email'
                            label='Email'
                            value={email}
                            onChange={this.handleChange}
                            required={true} />
                        <PasswordInput
                            id='password'
                            label='Password'
                            value={password}
                            onChange={this.handleChange}
                            required={true} />
                        {loading ? (
                            <FormButtons label={<div className='loader'></div>} className='disabled' />
                        ) : (
                            <FormButtons label='Register' />
                        )}
                    </form>
                )}
            </div>
        )
    }
}

export default withRouter(Register)

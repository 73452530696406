import React, { Component } from 'react'
import getCookie from '../utils/getCookie'

export class ToggleDarkmode extends Component {
    componentDidMount() {
        const darkmode = (getCookie('darkmode') === 'true');
        const expiry = new Date();
        expiry.setFullYear(expiry.getFullYear() + 1);
        document.cookie = `darkmode=${!darkmode};expires=${expiry.toUTCString()};path=/`
        document.documentElement.setAttribute('data-theme', darkmode ? 'light' : 'dark');

        const redir = this.props.location.state?.redir;
        this.props.history.push(redir ? redir : '/');
    }

    render() {
        return (<div></div>)
    }
}

export default ToggleDarkmode

import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Dropdown from './Dropdown'

export class NavLinks extends Component {
    render() {
        const {links, location, onClick} = this.props;
        const path = location.pathname;

        return (<>
            {links.map(link => {
                if (Array.isArray(link)) {
                    return (
                        <li key={link[0]}>
                            <Dropdown path={path} label={link[0]} links={link.slice(1)} onClick={onClick} />
                        </li>
                    );
                } else {
                    return (
                        <li key={link.label} className={`${link.url === path ? 'active' : ''}`}>
                            <Link key={link.label} to={link.url} onClick={onClick}>{link.label}</Link>
                        </li>
                    );
                }
            })}
        </>)
    }
}

export default withRouter(NavLinks)
